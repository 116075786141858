import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h1>
    <p>{`For testing, use the Staging base url: `}<a parentName="p" {...{
        "href": "https://stg-api-au.medipass.io/"
      }}>{`https://stg-api-au.medipass.io`}</a></p>
    <p>{`Our STG environment is connected to Medicare/DVA by default. Their adjudication engine will approve some claims and others might stay in a forever `}{`"`}{`under review`}{`"`}{` status. This is a condition of the Medicare/DVA non-production environment.`}</p>
    <p>{`We can manually request processing but for most instances, it`}{`'`}{`s best to stub the response by inserting the text `}{`"`}{`STUB`}{`_`}{`OK`}{`"`}{` into service text for each item claimed. For example:
`}{`"`}{`serviceText`}{`"`}{`: `}{`"`}{`STUB`}{`_`}{`OK`}{`"`}{`,  `}</p>
    <p>{`You can also simulate other Medicare/DVA decline/response codes by substituting `}<inlineCode parentName="p">{`STUB_OK with STUB_<ERROR_CODE>`}</inlineCode>{`, using any valid return code published here: `}<a parentName="p" {...{
        "href": "https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes?context=22796"
      }}>{`https://www.servicesaustralia.gov.au/medicare-digital-claiming-return-codes?context=22796`}</a></p>
    <p><strong parentName="p">{`Test accounts`}</strong></p>
    <p>{`Medicare provider and patient accounts undergo validation and established test accounts should be used. A spreadsheet of test accounts will be provided by our partner support team.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      